import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import GameContainer from '../../components/GameContainer';
import PageLayout from '../../components/PageLayout';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    setIgnoreModal,
    setShowCookieModal,
} from '../../store/reducers/consentSlice';
import * as styles from './index.module.scss';

const CookiePolicyPage = ({ location }) => {
    const consentSettings = useAppSelector((state) => state.consent);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setIgnoreModal(true));

        return () => {
            dispatch(setIgnoreModal(false));
        };
    }, []);

    return (
        <PageLayout>
            <Link to='/'>
                <img src='/skissan.svg' alt='Skissan' />
            </Link>
            <h1>Cookie Policy</h1>
            <Helmet>
                <title>Cookie Policy - Skissan</title>
            </Helmet>
            <GameContainer textAlign='left' containerSize='large'>
                <div className={styles.termsPage}>
                    <h2>What information is stored?</h2>
                    <p>We use cookies and similar technologies to:</p>
                    <ul>
                        <li>
                            Provide and improve content on Skissan, e.g. to
                            remember your settings in Skissan
                        </li>
                        <li>Temporarily session cookie to remain signed in</li>
                        <li>
                            Help personalize content and services, including ads
                        </li>
                    </ul>

                    <h2>Manage cookie consent</h2>
                    {consentSettings.hasAccepted ? (
                        <>
                            <p>
                                The cookie policy was last accepted on{' '}
                                {
                                    new Date(consentSettings.acceptedTs)
                                        .toISOString()
                                        .split('T')[0]
                                }
                            </p>
                            <p>
                                Show personalized ads:{' '}
                                {consentSettings.showPersonalizedAds
                                    ? 'Yes'
                                    : 'No'}
                            </p>
                        </>
                    ) : (
                        <p>No consent has been made</p>
                    )}
                    <button
                        className='button'
                        type='button'
                        onClick={() => dispatch(setShowCookieModal())}
                    >
                        Manage
                    </button>

                    <h2>Questions</h2>
                    <p>
                        If you have any questions about our cookie policy, then
                        you can contact us through our{` `}
                        <a href='https://slidesome.com/contact'>contact form</a>
                    </p>
                </div>
            </GameContainer>
            <div style={{ marginTop: '2rem' }}>
                <Link to='/games'>← Back to games list</Link>
            </div>
        </PageLayout>
    );
};

CookiePolicyPage.hideCookieModal = true;

export default CookiePolicyPage;
